<script setup lang="ts">
import { useCollapse } from "~/composables/tairo/collapse";

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk
      ? titleChunk + " - NMO Data"
      : "NMO Data";
  },
});

const route = useRoute();

let collapseEnabled = true;

if (route.query["hide-nav"] === "true") {
  collapseEnabled = false;
}

if (route.query["collapsed"] === "true") {
  const collapse = useCollapse();
  if (collapse.isOpen) {
    collapse.toggle();
  }
}

</script>

<template>
  <CollapseLayout toolbar="true" :collapse="collapseEnabled">
    <template v-if="collapseEnabled" #navigation>
      <NavBar />
    </template>

    <template #toolbar>
      <CollapseToolbar
        :collapse="collapseEnabled"
        :showNavBurger="true"
        class="flex-grow-0 flex-shrink-0"
      >
        <template #title>
          <h1 class="text-4xl text-secondary-500 font-medium">
            {{ $attrs["data-title"] }}
          </h1>
        </template>
      </CollapseToolbar>
    </template>

    <slot />
  </CollapseLayout>
</template>

<style>

html, body, #__nuxt {
  height: 100%;
}

#__nuxt > div {
  height: 100%;
}

/* TODO this actually a workaround and does not fix the root of the issue as these classes should automatically be
    applied, and this doesn't animate the transition. */
#nt-container {
  @apply fixed inset-0 pointer-events-none p-4 flex flex-col-reverse overflow-hidden z-[200] items-center gap-2 space-y-3;
}

</style>
